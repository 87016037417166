import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-locate-sample',
  templateUrl: './locate-sample.component.html',
  styleUrls: ['./locate-sample.component.css'],
})
export class LocateSampleComponent implements OnInit {
  cols: any;
  sampleData = [];
  selectedRow = [];
  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) { }
  locateForm = new FormGroup({
    sequenceNo: new FormControl(''),
    palletNo: new FormControl(''),
    boxNo: new FormControl(''),
  });
  ngOnInit() {
    this.generateHeaders(); //locate sample header
    this.loadData(); //locate sample lead data
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(
      JSON.stringify(this.cols.filter((x: any) => x.is_show))
    );
  }

  showForm = false;
  selectedCols: any[] = [];
  orgCol: any;
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(
      JSON.stringify(this.dataService.sortSelectedColumns(this.selectedCols))
    );
  }

  exportExcel() {
    let data = {
      sequenceNo: 0, //data sequence
      palletNo: 0, //data pallet no
      boxNo: 0, //data box no
    };
    this.dataService.GetSamplePositions(data).subscribe((response) => {
      if (!response['IsError']) {
        this.formatData(response.response)
      }
    });
  }


  formatData(data: any) {
    let tempUserdata = _.cloneDeep(data);
    const tempSample = tempUserdata.map((item: any) => {
      if (item.id) {
        delete item.id;
      }

      Object.keys(item).forEach((key: string) => {
        let keyName = this.cols.filter((item: any) => item.field == key);
        keyName = keyName.length > 0 ? keyName[0].header : key;
        item[keyName] = item[key] ? item[key].toString() : '';
        if (keyName.length > 0) {
          delete item[key];
        }
      });

      return { ...item };
    });
    this.dataService.exportExcel(tempSample, 'Locate Samples');
  }

  generateHeaders() {
    this.cols = [
      {
        serial_no: 1,
        field: 'sequenceNo',
        header: 'Sequence No.', //header sequence
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 2,
        field: 'palletNo',
        header: 'Pallete No.', //heasder pallet no
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 3,
        field: 'boxNo',
        header: 'Box No.', //header box no
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 4,
        field: 'status',
        header: 'Status', //header Status
        is_show: true,
        is_editable: true,
      },
      {
        serial_no: 5,
        field: 'expirationDate',
        header: 'Expiration Date', //header expiry date
        is_show: true,
        is_editable: true,
      },
    ];
  }
  loadData() {
    let data = {
      sequenceNo: 0, //data sequence
      palletNo: 0, //data pallet no
      boxNo: 0, //data box no
    };
    this.spinner.show()
    this.dataService.GetSamplePositions(data).subscribe((response) => {
      this.spinner.hide()
      if (!response['IsError']) {

        response.response.forEach((formData: any) => {
          formData.expirationDate = this.datePipe.transform(formData.expirationDate, 'MM/dd/yyyy')
        })
        this.sampleData = response.response;
      }
    });

  }
  searchData() {
    let data = {
      sequenceNo:
        this.locateForm.get('sequenceNo')!.value == null
          ? ''
          : this.locateForm.get('sequenceNo')!.value.toString(),
      palletNo:
        this.locateForm.get('palletNo')!.value == null
          ? ''
          : this.locateForm.get('palletNo')!.value.toString(),
      boxNo:
        this.locateForm.get('boxNo')!.value == null
          ? ''
          : this.locateForm.get('boxNo')!.value.toString(),
    };
    this.dataService.GetSamplePositions(data).subscribe((response) => {
      if (!response['IsError']) {
        this.sampleData = response.response; //search data sample response
      }
    });
  }
  onSelectRow(rowObject: { rowData: any }) {
    console.log(rowObject);
    let { rowData } = rowObject; //on select row event
    this.selectedRow = JSON.parse(JSON.stringify(rowData));
  }
  onSendSelectedRows(rowObject: any) {
    this.selectedRow = JSON.parse(JSON.stringify(rowObject)); //on send selected row event
    console.log(this.selectedRow);
  }
  deleteSelectedRow() {
    let data: any = this.selectedRow.map((item: any) => {
      return {
        //delete selected row event
        Id: item.id, //delete id
        SequenceNo: item.sequenceNo, //delete sequence
        PalletNo: item.palletNo, //delete pallet no
        BoxNo: item.boxNo, //delete box no
      };
    });
    this.dataService.DeleteSamples(data).subscribe({
      next: (response: any) => {
        if (!response['IsError']) {
          this.toastr.success(response.response);
          this.loadData(); //load after sample deleted
          this.selectedRow = [];
        }
      },
      error: (error) => {
        this.toastr.warning(error.error);
      },
    });
  }
  cancel() {
    this.locateForm.reset();
    this.loadData();
  }
}
