import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../services/dataService/data.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loan-entries',
  templateUrl: './loan-entries.component.html',
  styleUrls: ['./loan-entries.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoanEntriesComponent implements OnInit {
  cols: any;
  showForm = false;
  loanData: any;
  sampleDD: any;
  selectedRow: any;
  formType: any = 'New';
  returnLoan = false;

  loanForm = new FormGroup({
    sampleId: new FormControl('', Validators.required),
    loanerFirstName: new FormControl('', Validators.required),
    loanerlastName: new FormControl('', Validators.required),
    loanerBasfId: new FormControl(''),
    loanerEmail: new FormControl('', [Validators.email]),
    loanerDept: new FormControl(''),
    costCentre: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    remarks: new FormControl(''),
    returnLoan: new FormControl(false),
  });
  constructor(
    private service: DataService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private spinner:NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.generateHeaders();
    this.loadData();
    this.selectedCols = JSON.parse(JSON.stringify(this.cols));
    this.orgCol = JSON.parse(
      JSON.stringify(this.cols.filter((x: any) => x.is_show))
    );
  }

  selectedCols: any[] = [];
  orgCol: any;
  onChangeDDValue(_value: any) {
    this.cols = JSON.parse(
      JSON.stringify(this.service.sortSelectedColumns(this.selectedCols))
    );
  }

  exportExcel() {
    let tempUserdata = _.cloneDeep(this.loanData);
    const tempSample = tempUserdata.map((item: any) => {
      if (item.loanId) {
        delete item.loanId;
      }

      Object.keys(item).forEach((key: string) => {
        let keyName = this.cols.filter((item: any) => item.field == key);
        keyName = keyName.length > 0 ? keyName[0].header : key;
        item[keyName] = item[key] ? item[key].toString() : '';
        if (keyName.length > 0) {
          delete item[key];
        }
      });

      return { ...item };
    });
    this.service.exportExcel(tempSample, 'Loan Entries');
  }

  generateHeaders() {
    this.cols = [
      {
        serial_no: 1,
        field: 'sampleId',
        header: 'Sample No.',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 2,
        field: 'loanId',
        header: 'ID No.',
        is_show: false,
        is_editable: true,
      },
      {
        serial_no: 3,
        field: 'loanerFirstName',
        header: 'Loaner First Name',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 4,
        field: 'loanerLastName',
        header: 'Loaner Last Name',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 5,
        field: 'loanerBasfId',
        header: 'Loaner Basf ID',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 6,
        field: 'loanerEmail',
        header: 'Loaner Email',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 7,
        field: 'loanerDept',
        header: 'Loaner Dept',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 8,
        field: 'costCentre',
        header: 'Cost Center',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 9,
        field: 'startDate',
        header: 'Start Date',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 10,
        field: 'endDate',
        header: 'End Date',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 11,
        field: 'remarks',
        header: 'Remarks',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 12,
        field: 'createdDate',
        header: 'Created Date',
        is_show: true,
        is_editable: false,
      },
      {
        serial_no: 13,
        field: 'isReturned',
        header: 'Is Loan Returned',
        is_show: true,
        is_editable: false,
      },
    ];
  }
  loadData() {
    this.spinner.show()
    this.service.GetLoanEntries().subscribe((res: any) => {
      this.spinner.hide()
      let tempData = res.response.map((item: any) => {
        let tempItem = {
          ...item,
          startDate: this.datepipe.transform(item.startDate, 'MM/dd/yyyy'),
          endDate: this.datepipe.transform(item.endDate, 'MM/dd/yyyy'),
          createdDate: this.datepipe.transform(item.createdDate, 'MM/dd/yyyy'),
          isReturned: item.isReturned 
        };
        return tempItem;
      });
      this.loanData = tempData;
    });
    this.service.GetAllSamplePositions().subscribe((res) => {
      this.sampleDD = res.response;
    });
  }
  addNewLoan() {
    this.showForm = true;
    this.formType = 'New';
    this.loanForm.controls['sampleId'].enable();
    this.loanForm.reset();
    this.returnLoan = false;
  }
  onSelectRow(rowObject: { rowData: any }) {
    let { rowData } = rowObject;
    this.selectedRow = JSON.parse(JSON.stringify(rowData));

    this.formType = 'Edit';
    this.showForm = true;
    let tempSampleId = this.sampleDD.filter((item: any) => {
      return item.sequenceNo == this.selectedRow.sampleId;
    })[0].id;

    this.loanForm.controls['sampleId'].setValue(tempSampleId);
    this.loanForm.controls['sampleId'].disable();
    this.loanForm.controls['loanerFirstName'].setValue(
      this.selectedRow.loanerFirstName
    );
    this.loanForm.controls['loanerlastName'].setValue(
      this.selectedRow.loanerLastName
    );
    this.loanForm.controls['loanerBasfId'].setValue(
      this.selectedRow.loanerBasfId
    );
    this.loanForm.controls['loanerEmail'].setValue(
      this.selectedRow.loanerEmail
    );
    this.returnLoan = this.selectedRow.isReturned;
    this.loanForm.controls['loanerDept'].setValue(this.selectedRow.loanerDept);
    this.loanForm.controls['costCentre'].setValue(this.selectedRow.costCentre);
    this.loanForm.controls['startDate'].setValue(
      this.selectedRow.startDate == null ? null : this.selectedRow.startDate
    );
    this.loanForm.controls['endDate'].setValue(
      this.selectedRow.endDate == null ? null : this.selectedRow.endDate
    );
    this.loanForm.controls['remarks'].setValue(this.selectedRow.remarks);
    this.loanForm.controls['returnLoan'].setValue(this.returnLoan);
  }
  onSendSelectedRows(rowObject: any) {
    this.selectedRow = JSON.parse(JSON.stringify(rowObject));
    console.log(this.selectedRow);
  }
  cancel() {
    this.showForm = false;
    this.loanForm.reset();
  }

  submitForm() {
    const startDate=new Date(this.loanForm.get('startDate')!.value)
    startDate.setDate(startDate.getDate()+1)
    const endDate=new Date(this.loanForm.get('endDate')!.value)
    endDate.setDate(endDate.getDate()+1)
    let dataLoad = {
      loanId: 0,
      costCentre: this.loanForm.get('costCentre')!.value,
      endDate:
        this.loanForm.get('endDate')!.value == null
          ? null
          : endDate,
      loanerBasfId: this.loanForm.get('loanerBasfId')!.value,
      loanerDept: this.loanForm.get('loanerDept')!.value,
      loanerEmail: this.loanForm.get('loanerEmail')!.value,
      loanerFirstName: this.loanForm.get('loanerFirstName')!.value,
      loanerLastName: this.loanForm.get('loanerlastName')!.value,
      remarks: this.loanForm.get('remarks')!.value,
      isReturned: this.returnLoan,
      sampleId: this.loanForm.get('sampleId')!.value,
      startDate:
        this.loanForm.get('startDate')!.value == null
          ? null
          : startDate,
    };
    this.showForm = false;

    if (this.formType != 'New') {
      dataLoad.loanId = this.selectedRow.loanId;
    }
    this.service.insertloandetails(dataLoad).subscribe({
      next: (res) => {
        console.log(res);
        this.loanForm.reset();
        this.toastr.success('Created Successfully.');
        this.loadData();
      },
      error: (error) => {
        this.toastr.warning(error.error);
      },
    });
  }
}
